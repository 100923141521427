import React, { useEffect } from "react"
import Carousel from "../components/Carousel"
import CompanyDetails from "../components/CompanyDetails"
import { eximInfo, eximProducts, eximSlides } from "../constant"
import Products from "../components/Products"
// import Clients from '../components/Clients'

const GEL = () => {
  useEffect(() => {
    document.title = "Greentouch Exim Ltd"
  }, [])

  return (
    <div className="snap-proximity snap-y">
      <div className="snap-center">
        <Carousel slides={eximSlides} />
      </div>
      {eximInfo.map((info) => (
        <div className="snap-center">
          <CompanyDetails
            title={info.title}
            detail={info.detail}
            style={info.style}
          />
        </div>
      ))}

      <Products products={eximProducts} />
      {/* <Clients clients={eximClients} /> */}
    </div>
  )
}

export default GEL
